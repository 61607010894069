html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}

code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}

code[class*='language-'] {
  padding: 0;
}

/* Sliding animations */
@keyframes slideInRightToLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutLeftToRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.sidebar-enter {
  animation: slideInRightToLeft 0.3s forwards;
}

.sidebar-exit {
  animation: slideOutLeftToRight 0.3s forwards;
}

.tiptap {
  padding: 10px;
  border: none;
  min-height: 300px;
}

.tiptap:focus-visible {
  border: none;
  outline: none;
  min-height: 300px;
}

.tiptap a {
  color: var(--purple);
  cursor: pointer;
  text-decoration: underline;
}

.Toastify__toast {
  min-height: 50px;
}

.Toastify__close-button {
  position: relative;
  top: 8px;
  right: 10px;
  color: #000;
  opacity: 0.7;
}
.Toastify__toast-body {
  font-weight: 700;
  opacity: 1;
}

.sfc-main-container .input-row label {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.62px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sfc-main-container #ccCardNumber,
.sfc-main-container #ccExpYear,
.sfc-main-container #ccCVV {
  border-radius: 24px !important;
}

.sfc-main-container input[type='text'],
.sfc-main-container input[type='date'],
.sfc-main-container input[type='password'],
.sfc-main-container input[type='number'],
.sfc-main-container select {
  border-radius: 24px !important;
}

.sfc-main-container .sfc-cfa-button {
  border-radius: 24px !important;
}

.sfc-main-container.horizontal .main-title,
.sfc-main-container.tiles .main-title {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sfc-main-container.horizontal .pm-title,
.sfc-main-container.tiles .pm-title {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sfc-main-container p {
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 600;
  line-height: 13.02px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.sfc-main-container .sfc-cfa-button {
  background-color: #6461f3 !important;
}

.nuvei-heading {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  text-transform: uppercase;
}

.nuvei-name {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.nuvei-text {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.83px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.nuvei-amount {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.nuvei-hr {
  border: 1px solid #1f1933;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.nuvei-pay {
  font-family: DM Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.bg-dark {
  stroke: white !important;
  fill: white !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
